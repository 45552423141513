import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const role = this.authService.getRole();
    const isUser = this.authService.isUser();

    if (!isUser) {
      if (role === 'admin') {
        this.router.navigate(['/admin/dashboard'], {
          queryParams: route.queryParams,
        });
      } else if (role === 'instructor') {
        this.router.navigate(['/instructor/dashboard'], {
          queryParams: route.queryParams,
        });
      }
    }

    return isUser;
  }
}
