import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class InstructorGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const role = this.authService.getRole();
    const isTrainer = this.authService.isTrainer();

    if (!isTrainer) {
      if (role === 'admin') {
        this.router.navigate(['/admin/dashboard'], {
          queryParams: route.queryParams,
        });
      } else if (role === 'user') {
        this.router.navigate(['/home/dashboard'], {
          queryParams: route.queryParams,
        });
      }
    }

    return isTrainer;
  }
}
